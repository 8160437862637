<template>
  <Base titleIcon="el-icon-s-claim">
    <el-space wrap style="margin-bottom: 12px">
      <el-select
        collapse-tags
        v-model="filter.year"
        multiple
        placeholder="资金安排年度"
      >
        <el-option
          v-for="item in yearDic"
          :key="item"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>
      <el-select
        collapse-tags
        v-model="filter.city"
        multiple
        placeholder="市级"
      >
        <el-option
          v-for="item in cityDic"
          :key="item"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>
      <el-select
        collapse-tags
        v-model="filter.budget_area"
        multiple
        placeholder="补助范围"
      >
        <el-option
          v-for="item in budget_areaDic"
          :key="item"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>
      <el-select
        collapse-tags
        v-model="filter.budget_thing"
        multiple
        placeholder="补助事项"
      >
        <el-option
          v-for="item in budget_thingDic"
          :key="item"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>
      <el-select
        collapse-tags
        v-model="filter.type"
        multiple
        placeholder="项目类别"
      >
        <el-option
          v-for="item in typeDic"
          :key="item"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>

      <el-input
        placeholder="项目名称、编号、保护单位名称"
        v-model="filter.keyword"
        style="width: 500px"
        @keyup.enter="search"
      >
        <template #prepend> 关键字 </template>
      </el-input>
      <el-button type="primary" style="margin-left: 5px" @click="search"
        >查询</el-button
      >
    </el-space>
    <el-table :data="fundFilters" v-loading="isLoading">
      <el-table-column
        label="资金安排年度"
        prop="资金安排年度"
      ></el-table-column>
      <el-table-column label="市级" prop="市级"> </el-table-column>
      <el-table-column label="项目编号" prop="项目编号"></el-table-column>
      <el-table-column label="项目名称" prop="项目名称"></el-table-column>
      <el-table-column
        label="保护单位名称"
        prop="保护单位名称"
      ></el-table-column>
      <el-table-column label="补助范围" prop="补助范围"> </el-table-column>
      <el-table-column label="补助事项" prop="补助事项"> </el-table-column>
      <el-table-column
        label="安排金额（万元）"
        prop="安排金额（万元）"
      ></el-table-column>
      <el-table-column label="项目类别" prop="项目类别"></el-table-column>
    </el-table>
  </Base>
</template>

<script lang="ts">
import Base from "@/views/layout/Base.vue";
import {
  fund,
  yearDic,
  cityDic,
  budget_areaDic,
  budget_thingDic,
  typeDic,
} from "./data.js";
import { defineComponent, onMounted, reactive, ref, watch } from "vue";

export default defineComponent({
  components: {
    Base,
  },
  setup() {
    const isLoading = ref(false);
    const fundFilters = ref([]);
    const filter = reactive({
      year: [],
      city: [],
      type: [],
      budget_area: [],
      budget_thing: [],
      keyword: null,
    });
    const search = () => {
      isLoading.value = true;
      setTimeout(() => {
        fundFilters.value = fund.filter(
          (p) =>
            (filter.year.length == 0 ||
              filter.year.some((f) => f == p.资金安排年度)) &&
            (filter.city.length == 0 || filter.city.some((f) => f == p.市级)) &&
            (filter.budget_area.length == 0 ||
              filter.budget_area.some((f) => f == p.补助范围)) &&
            (filter.budget_thing.length == 0 ||
              filter.budget_thing.some((f) => f == p.补助事项)) &&
            (filter.type.length == 0 ||
              filter.type.some((f) => f == p.项目类别)) &&
            (filter.keyword == null ||
              p.项目名称.indexOf(filter.keyword) > -1 ||
              p.项目编号.indexOf(filter.keyword) > -1 ||
              p.保护单位名称.indexOf(filter.keyword) > -1)
        );
        isLoading.value = false;
      }, 100);
    };
    onMounted(() => {
      search();
      watch(
        [
          () => filter.year,
          () => filter.city,
          () => filter.type,
          () => filter.budget_area,
          () => filter.budget_thing,
        ],
        (v, o) => {
          search();

          // if (typeof v != "string")
          //console.log(v.keyword, o.keyword);
        }
      );
    });

    return {
      isLoading,
      budget_areaDic,
      budget_thingDic,
      typeDic,
      cityDic,
      yearDic,
      filter,
      fundFilters,
      search,
    };
  },
});
</script>

<style scoped>
</style>