
import Base from "@/views/layout/Base.vue";
import {
  fund,
  yearDic,
  cityDic,
  budget_areaDic,
  budget_thingDic,
  typeDic,
} from "./data.js";
import { defineComponent, onMounted, reactive, ref, watch } from "vue";

export default defineComponent({
  components: {
    Base,
  },
  setup() {
    const isLoading = ref(false);
    const fundFilters = ref([]);
    const filter = reactive({
      year: [],
      city: [],
      type: [],
      budget_area: [],
      budget_thing: [],
      keyword: null,
    });
    const search = () => {
      isLoading.value = true;
      setTimeout(() => {
        fundFilters.value = fund.filter(
          (p) =>
            (filter.year.length == 0 ||
              filter.year.some((f) => f == p.资金安排年度)) &&
            (filter.city.length == 0 || filter.city.some((f) => f == p.市级)) &&
            (filter.budget_area.length == 0 ||
              filter.budget_area.some((f) => f == p.补助范围)) &&
            (filter.budget_thing.length == 0 ||
              filter.budget_thing.some((f) => f == p.补助事项)) &&
            (filter.type.length == 0 ||
              filter.type.some((f) => f == p.项目类别)) &&
            (filter.keyword == null ||
              p.项目名称.indexOf(filter.keyword) > -1 ||
              p.项目编号.indexOf(filter.keyword) > -1 ||
              p.保护单位名称.indexOf(filter.keyword) > -1)
        );
        isLoading.value = false;
      }, 100);
    };
    onMounted(() => {
      search();
      watch(
        [
          () => filter.year,
          () => filter.city,
          () => filter.type,
          () => filter.budget_area,
          () => filter.budget_thing,
        ],
        (v, o) => {
          search();

          // if (typeof v != "string")
          //console.log(v.keyword, o.keyword);
        }
      );
    });

    return {
      isLoading,
      budget_areaDic,
      budget_thingDic,
      typeDic,
      cityDic,
      yearDic,
      filter,
      fundFilters,
      search,
    };
  },
});
